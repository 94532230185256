









import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "SavedToast",
    props: {
        target: String,
        object: String
    }
})
