










import { computed, defineComponent } from "@vue/composition-api";
import { translate } from "../../plugins/multi-language";

export default defineComponent({
    name: "SuccessRequest",
    props: {
        requestTarget: String
    },
    setup(props) {
        const requestGoal = computed(() => {
            return translate(`request.${props.requestTarget}`);
        })

        return {
            requestGoal
        }
    }
})
