




import { defineComponent } from "@vue/composition-api";
import router from "../router";
import { useAuth } from "@/api_connectors/auth";

export default defineComponent({
    name: "LogoutButton",
    setup() {
        const {logout} = useAuth()

        const logoutUser = async () => {
            // console.log('logout')
            await logout()
            // console.log(response)
            if(router.currentRoute.meta?.admin) return await router.push('/admin')
            await router.push('/')
        }

        return {
            logoutUser
        }
    }
})
