













import { computed, defineComponent } from "@vue/composition-api";
import { translate } from "../../plugins/multi-language";

export default defineComponent({
    name: "FailedRequest",
    props: {
        requestTarget: String,
        additionalInfo: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const requestGoal = computed(() => {
            return translate(`request.${props.requestTarget}`);
        })

        const additionalInfoTranslated = computed(() => {
            if (!props.additionalInfo) return ''
            return translate(`error.${props.additionalInfo}`);
        })

        return {
            requestGoal,
            additionalInfoTranslated
        }
    }
})
