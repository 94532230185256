import { useApi } from "@/composables/useApi";

export function useSessionAPI() {
    const {api} = useApi()

    const getUserSessions = async () => {
        const response = await api.get(api.defaults.baseURL + 'api/user/session/')
        // console.log(response)

        return response.data
    }

    const destroySessionWithId = async (sessionId: string) => {
        const response = await api.delete(api.defaults.baseURL + 'api/user/session/' + sessionId)
        // console.log(response)

        return response.data
    }

    return {
        getUserSessions,
        destroySessionWithId
    }
}