





















import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { useSessionAPI } from "@/api_connectors/session";
import { translate } from "@/plugins/multi-language";
import { TOKEN_KEY } from "@/composables/useApi";

export default defineComponent({
    name: "SessionManagement",
    setup() {
        //TODO: Change this request
        interface Session {
            _id: string;
            sessionID: string;
            state: {
                content: {
                    sessionType: string;
                }
                createdAt: number;
                flash: object;
                id: string;
                updatedAt: number;
                userId: string;
            }
        }

        interface SessionInfos {
            // createdAt: string;
            updatedAt: string;
            sessionType: string;
            destroySession: string;
            device?: string;
        }

        const { getUserSessions, destroySessionWithId } = useSessionAPI()
        let userSessions = reactive<Session[]>([])
        let sessionInfos = reactive<SessionInfos[]>([])
        const currentSessionToken = ref(localStorage.getItem(TOKEN_KEY))

        let sessionField = [
            { key: 'sessionType', label: translate('session.source') },
            // {key: 'createdAt', label: translate('session.firstDate')},
            { key: 'updatedAt', label: translate('session.lastUse') },
            { key: 'destroySession', label: translate('session.destroy') }
        ]

        const update = async (): Promise<void> => {
            userSessions.splice(0)
            userSessions.push(...(await getUserSessions()))
            sessionInfos.splice(0)
            // userSessions = await getUserSessions()
            for (const session of userSessions) {
                if (!session.state?.createdAt || !session.state?.updatedAt) continue;
                const dateOptions = {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }
                // const createTimeStamp = new Date(session.state.createdAt * 1000).toLocaleDateString(undefined, dateOptions)
                const updateTimeStamp = new Date(session.state.updatedAt * 1000).toLocaleDateString(undefined, dateOptions)

                sessionInfos.push({
                    sessionType: session.state.content.sessionType,
                    // createdAt: createTimeStamp,
                    updatedAt: updateTimeStamp,
                    destroySession: session.sessionID
                })
            }
            // console.log(userSessions)
            // console.log(sessionInfos)
        }

        const destroySession = async (sessionID: string): Promise<void> => {
            // console.log(sessionID)
            if (sessionID === currentSessionToken.value) return;
            await destroySessionWithId(sessionID)
            await update()
        }

        const updateFields = () => {
            sessionField = [
                { key: 'sessionType', label: translate('session.source') },
                { key: 'createdAt', label: translate('session.firstDate') },
                { key: 'updatedAt', label: translate('session.lastUse') },
                { key: 'destroySession', label: translate('session.destroy') }
            ]
        }

        onMounted(() => update())

        return {
            sessionInfos,
            sessionField,
            currentSessionToken,
            destroySession,
            updateFields
        }
    }
})
