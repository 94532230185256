




















import { defineComponent, ref } from "@vue/composition-api";
import { translate } from "@/plugins/multi-language";
import { useUserAPI } from "@/api_connectors/user";

export default defineComponent({
    name: "Unsubscribe",
    setup() {
        const reason = ref(0)
        const { unsubscribeNews } = useUserAPI()

        const submitEntry = () => {
            unsubscribeNews(unsubscribeReason());
        }

        const options = [
            { value: 0, text: translate('select.noLike') },
            { value: 1, text: translate('select.noCare') },
            { value: 3, text: translate('select.noReason') }
        ]

        const unsubscribeReason = (): string => {
            switch (reason.value) {
                case 0:
                    return 'noLike';
                case 1:
                    return 'noCare';
                case 2:
                    return 'noReason';
                default:
                    return 'noReason';
            }
        }

        return {
            options,
            reason,
            submitEntry
        }
    }
})
