





























































































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import LogoutButton from "@/components/LogoutButton.vue";
import { useUserAPI } from "@/api_connectors/user";
import { SplashUserType, UserSetRequest } from "@/types/typeDefinitions";
import Unsubscribe from "@/components/Modal/Unsubscribe.vue";
import SessionManagement from "@/components/SessionManagement.vue";
import { translate } from "@/plugins/multi-language"
import deleteProperty = Reflect.deleteProperty;
import SavedToast from "@/components/Toast/SavedToast.vue";
import { useToast } from "@/composables/useToast";
import SuccessRequest from "@/components/Toast/SuccessRequest.vue";
import FailedRequest from "@/components/Toast/FailedRequest.vue";

export default defineComponent({
    name: "User",
    components: { FailedRequest, SuccessRequest, SavedToast, SessionManagement, Unsubscribe, LogoutButton },
    setup() {
        const { getUserData, setUserData, resendVerificationRequest } = useUserAPI()
        const { showToast } = useToast()

        let userData = reactive<UserSetRequest>({
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            licenses: [],
            newsletter: false,
            type: SplashUserType.teacher,
            oldPW: ''
        })
        const isUpdated = ref(false)
        const oldPassword = ref('')
        const passwordConfirm = ref('')

        let options = reactive([
            { value: 'none', text: translate('types.none') },
            { value: 'teacher', text: translate('types.teacher') },
            { value: 'student', text: translate('types.student') },
            { value: 'regular', text: translate('types.regular') },
            { value: 'school', text: translate('types.school') },
        ])


        const update = async () => {
            Object.assign(userData, userData, await getUserData())
            // userData = await getUserData()
            // console.log(userData)
            isUpdated.value = true;
        }

        const saveData = async () => {
            // console.log(userData)
            const currentUserData = JSON.parse(JSON.stringify(userData))
            if (oldPassword.value !== '') {
                if (currentUserData.password !== passwordConfirm.value) return

                currentUserData.oldPW = oldPassword.value
            } else {
                deleteProperty(currentUserData, 'password')
                deleteProperty(currentUserData, 'oldPW')
            }
            if (currentUserData.pendingEmail) currentUserData.email = currentUserData.pendingEmail;
            const response = await setUserData(currentUserData)

            if (response) return showToast('saved-toast')
            showToast('save-failed-toast')
        }

        const checkForUnsub = (openModal: Function): void => {
            if (!userData.newsletter) openModal();
        }

        const resendVerification = async (): Promise<void> => {
            const response = await resendVerificationRequest()

            if (response) return showToast('success-request')
            showToast('failed-request')
        }

        onMounted(() => update())

        return {
            userData,
            options,
            isUpdated,
            oldPassword,
            passwordConfirm,
            saveData,
            checkForUnsub,
            resendVerification
        }
    }
})
