import { useApi } from "@/composables/useApi";
import { User, UserSetRequest } from "@/types/typeDefinitions";
import { AxiosError, AxiosResponse } from "axios";

export function useUserAPI() {
    const {api} = useApi()

    const getUserData = async (): Promise<User | undefined> => {
        const response = await api.get('api/user/userData')
            .catch((err) => {
                console.log(err)
                return undefined
            })

        if (!response) return undefined
        // console.log(response)

        return response.data
    }

    const getAllUserData = async () => {
        const response = await api.get('api/admin/userManagement/userList')
        // console.log(response)

        return response.data
    }

    const adminGetUserData = async (userId: string) => {
        const response = await api.get('api/admin/userManagement/get/' + userId)
        // console.log(response)

        return response.data
    }

    const setUserDataAdmin = async (user: User) => {
        return api.post('api/admin/userManagement/set', { user: user })
    }

    const setUserData = async (userSetRequest: UserSetRequest): Promise<boolean> => {
        const response = await api.post('api/user/userData', { userSetRequest: userSetRequest })
            .catch(() => {
                return undefined
            })

        return Boolean(response)
    }

    const deleteUser = async (userId: string): Promise<AxiosResponse | undefined> => {
        return api.delete('api/admin/userManagement/delete/' + userId)
            .catch(() => {
                return undefined
            });
    }

    const createUser = async (user: User): Promise<AxiosResponse | undefined> => {
        return api.post('api/admin/userManagement/create', { user: user })
            .catch(() => {
                return undefined
            })
    }

    const unsubscribeNews = async (providedReason: string): Promise<AxiosResponse | undefined> => {
        return api.post('api/user/unsubscribeNewsletter', { reason: providedReason })
            .catch(() => {
                return undefined
            })
    }

    const resendVerificationRequest = async (): Promise<AxiosResponse | undefined> => {
        return await api.post('api/userCredentials/resendVerifyEmail')
            .catch(() => {
                return undefined
            })
    }

    return {
        getUserData,
        setUserData,
        getAllUserData,
        setUserDataAdmin,
        adminGetUserData,
        deleteUser,
        createUser,
        unsubscribeNews,
        resendVerificationRequest
    }
}